import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomeView from '../VIEWS/HomeView';
import AdminView from '../VIEWS/AdminView';
import SimView from '../VIEWS/SimView'


const RoutingController: React.FunctionComponent = props => {

    useEffect(() => { }, [])

    return(
        <BrowserRouter>
        <Routes>
            <Route path = "/home" element = { <HomeView /> }></Route>
            <Route path = "/simview" element = { <SimView /> }></Route>
            <Route path = "/adminview" element = { <AdminView /> }></Route>
            <Route path = "/" element = { <HomeView  /> }></Route>
        </Routes>

    </BrowserRouter>
           
    )

}

export default RoutingController
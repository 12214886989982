import React, { useEffect, useState } from 'react';
import { loadUsers, usersModifier } from '../CONTROLLERS/UserController'
import { createErrorMessage } from './ErrorController'
import RoutingController from './RoutingController';
import UserType from '../MODELS/USER/User';
import UserContext from '../MODELS/USER/UserContext';
import SimDataType from '../MODELS/SIMDATA/SimData';
import SimDataContext from '../MODELS/SIMDATA/SimDataContext';
import { loadSimData } from './SimDataController';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContextController: React.FunctionComponent = () => {


// ------------------------------------------------------------------------------------------------------------------- USERS
    const [ users, setUsers ] = useState<UserType[]>([]);
    const updateUsers = () => {
      return new Promise<any>(async (resolve, reject) => {
        loadUsers()
        .then(response => { 
          if(response.length !== 0){
            setUsers(response); 
            resolve('USERS RELOAD')
          }else{
            createErrorMessage('UPDATEUSERS ERROR -> LOADUSERS RESPONSE.LENGTH === 0'); 
            reject('USERS RELOAD ERROR');
          } })
        .catch(error => { 
          createErrorMessage(`UPDATEUSERS ERROR: ${error.message}`)
          reject('USERS RELOAD ERROR');
        })
      })
    }

     const modifyUsers = (user: UserType, action: string) => {
      let usersShallow: UserType[] = users
  
      return new Promise<any>(async (resolve, reject) => {  
        usersModifier(user, usersShallow, action)
        .then(response => {
          setTimeout(() => { 
            updateUsers()
            .then(response => { 
              resolve('USERS MODIFIED')})
            .catch(err => { 
              reject('USERS MODIFIED ERROR')}) 
          }, 1000)
        })
        .catch(error => { reject('MODIFYTUFFERROR ') })
      })
      
    }
  
    const [ actualUser, setActualUser ] = useState<UserType>({id: 0, buttons: '', email: '', role: '', status: '', token:'', tokenTimestamp: ''});
    const updateActualUser = (user: UserType) => { setActualUser(user); toast(`Bejelentkezve`) }

// ------------------------------------------------------------------------------------------------------------------- USERS
const [ simData, setSimData ] = useState<SimDataType[]>([]);
const updateSimData = () => {
  return new Promise<any>(async (resolve, reject) => {
    loadSimData()
    .then(response => { 
      if(response.length !== 0){
        setSimData(response); 
        resolve('SIMDATA RELOAD')
      }else{
        createErrorMessage('UPDATESIMDATA ERROR -> LOADSIMDATA RESPONSE.LENGTH === 0'); 
        reject('SIMDATA RELOAD ERROR');
      } })
    .catch(error => { 
      createErrorMessage(`UPDATESIMDATA ERROR: ${error.message}`)
      reject('SIMDATA RELOAD ERROR');
    })
  })
}    
 
    useEffect(() =>{
      updateUsers()
      .then(response => { 
        setTimeout(() => { 
          updateSimData()
          .then(response => { })
          .catch(error => { })
        }, 1000)
      })
      .catch(error => { })
      
    }, [])

    return(
      <UserContext.Provider value = {{actualUser, updateActualUser, users, updateUsers, modifyUsers}}>
        <SimDataContext.Provider value = {{simData, updateSimData}}>
          <RoutingController />
          <ToastContainer />
        </SimDataContext.Provider>
      </UserContext.Provider>
    )

}

export default ContextController
import React, { useRef, useEffect } from 'react'


function Video() {

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

    useEffect(() => { attemptPlay();
    }, [])



  return (

      <video
        style={{ maxWidth: '90%', width: '800', height: '90%', margin: "0 auto" }}
        playsInline
        loop
        muted
        controls
        alt="All the devices"
        src="./sample.mp4"
        ref={videoEl}
      />
   

    );

  }


export default Video;

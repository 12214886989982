import { createContext } from 'react';
import UserInterface from './UserInterface';


const defaultState = {
   users: [{id: 0, buttons: '', email: '', role: '', status: '', token:'', tokenTimestamp: ''}],
   updateUsers: () => {},
   modifyUsers: () => {},
   actualUser: {id: 0, buttons: '', email: '', role: '', status: '', token:'', tokenTimestamp: ''},
   updateActualUser: () => {},
};

const UserContext = createContext<UserInterface>(defaultState);

export default UserContext


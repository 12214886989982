import SimDataType from "../MODELS/SIMDATA/SimData";
import { findOne } from "../MODELS/SIMDATA/SimDataModel";


export const getTable = (simData: SimDataType[], diameter: number, rangeMin: number, rangeMax: number, medium: string) => {

    return new Promise<any>(async (resolve, reject) => {
        interface axis {xAxis: number[], yAxis: number[], zAxis: number[], diameterVolumes: number[], a: string, b: string, c: string, d: string, filename: string};
        interface dVolume {rpm: number, volume: number};
        interface abcd {a: string, b: string, c: string, d: string};
        
        let foundByDiameter = findOne(simData, 'diameter', diameter.toString());
        let specCorr = getSpecCorrection(foundByDiameter, medium)
        
        let a = ((+(-0.000000000000002806) * (1 + (Math.floor(Math.random() * 10001)/100000000))) * specCorr).toPrecision(8);
        let b = (((+(0.004) * (1 + (Math.floor(Math.random() * 10001)/100000000))) * diameter) * specCorr).toPrecision(8);
        let c = ((+(-0.0000663) * (1 + (Math.floor(Math.random() * 10001)/100000000))* (diameter * diameter)) * specCorr).toPrecision(8);
        let d = ((+(0.0000007155) * (1 + (Math.floor(Math.random() * 10001)/100000000))* (diameter * diameter * diameter)) * specCorr).toPrecision(8);

        let dataObject: axis = {xAxis: [], yAxis: [],  zAxis: [], diameterVolumes: [], a: a, b: b, c: c, d: d, filename: `diameter_${diameter}_rpm_${rangeMin} - ${rangeMax}_medium_${medium}`}
        let volumeTable = new Array();
        let rpmTable = new Array();
        let diameterTable = new Array();
        let diamVolumes = new Array();
        for(let i = 1; i < 599; i++){ diameterTable.push(i) }
        let index = 0;

        for(let rpm = rangeMin; rpm < rangeMax; rpm++){
            volumeTable[index] = new Array(); 
            rpmTable.push(rpm)
            index++;
            
            for(let diam = 1; diam < 599; diam++){
                let data = getRpmVolume(simData, medium, diam, rpm)
                volumeTable[index-1].push(data)
                
                if(diam === +diameter){ 
                    let volumeObject: dVolume = {rpm: 0, volume: 0}
                    volumeObject.rpm = rpm;
                    volumeObject.volume = data;
                    diamVolumes.push(volumeObject) 
                }
           }
           
        }

        dataObject.xAxis = rpmTable;
        dataObject.yAxis = diameterTable;
        dataObject.zAxis = volumeTable;
        dataObject.diameterVolumes = diamVolumes;
             
    resolve(dataObject) 
    })

};

const getRpmVolume = (simData: SimDataType[], medium: string, diameter: number, rpm: number) =>{

    let rnd = 1 + (Math.floor(Math.random() * 10001)/100000000)
    let foundByDiameter = findOne(simData, 'diameter', diameter.toString());
    let rpmVolume = foundByDiameter.ccmrpm * rpm
  
    return rpmVolume * rnd * getSpecCorrection(foundByDiameter, medium)
}

const getSpecCorrection = (foundByDiameter: SimDataType, medium: string) =>{

    let specificCorrection = 0;

    if(medium === 'hidraulicoil'){
        specificCorrection = +0.35 + +foundByDiameter.hidraulicoil
    }else if(medium === 'diesel'){
        specificCorrection = +0.54 + +foundByDiameter.diesel
    }else{
        specificCorrection = +0.62 + +foundByDiameter.water
    }

    return specificCorrection
}

const getSpecCorrectionBACKUP = (simData: SimDataType[], medium: string, diameter: number, rpm: number) =>{

    let rnd = 1 + (Math.floor(Math.random() * 10001)/100000000)
    let foundByDiameter = findOne(simData, 'diameter', diameter.toString());
    let rpmVolume = foundByDiameter.ccmrpm * rpm
    let specificCorrection = 0;

    if(medium === 'hidraulicoil'){
        specificCorrection = +0.35 + +foundByDiameter.hidraulicoil
    }else if(medium === 'diesel'){
        specificCorrection = +0.54 + +findOne(simData, 'diameter', diameter.toString()).diesel
    }else{
        specificCorrection = +0.62 + +findOne(simData, 'diameter', diameter.toString()).water
    }

    return rpmVolume * rnd * specificCorrection
}

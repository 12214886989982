import React, { useContext, useState } from 'react'
import MultiRangeSlider from "multi-range-slider-react";
import 'react-toastify/dist/ReactToastify.css';
import SimDataContext from '../MODELS/SIMDATA/SimDataContext';
import { getTable } from '../TOOLS/Simulator';
import { toast } from 'react-toastify';

interface axis {xAxis: number[], yAxis: number[], zAxis: number[]};

interface IInputControllerProps {
  datas: axis,
  updateDatas: (dataObject: {xAxis: number[], yAxis: number[], zAxis: number[], diameterVolumes: any[], a: string, b: string, c: string, d: string, filename: string}) => void;
  videoplot: string;
  updateVideoplot: () => void;
  loading: boolean;
  updateLoading: (setter: boolean) => void;
}

const InputController: React.FunctionComponent<IInputControllerProps> = ( props ) => {
 
  const [ input, setInput ] = useState({diameter: 0, medium: ''})
  const { simData } = useContext(SimDataContext)
  const [minValue, set_minValue] = useState(1);
  const [maxValue, set_maxValue] = useState(600);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void => {
    setInput({...input, [e.target.name]: e.target.value })
  }

  const handleRange = (e: { minValue: React.SetStateAction<number>; maxValue: React.SetStateAction<number>; }) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };
 

  const init = (): void => {
    if(input.medium === ''){
      toast('Válassza ki a kívánt közeget!')
    }else{
      props.updateLoading(true);
      setTimeout(() => { 
        getTable(simData, input.diameter, minValue, maxValue, input.medium)
          .then((response: any) => { 
            props.updateLoading(false);
            setTimeout(() => { props.updateDatas(response!) }, 1500)
          })
          .catch(err => { console.log(err)})
        }, 1500)
      if(props.videoplot === 'video'){ props.updateVideoplot(); }
      
    }
       
  }
    

    
  return (
    <div className = 'wrapper width-100 height-100 align-left column' >
      <div className = 'wrapper width-100 align-center column margin-bottom-2' id = 'input'>
        <div className = 'label font-300 color-500 weight-200'>Fordulatszám tartomány [1/min]</div>
        <div className = 'wrapper width-100 height-30 row justify-center align-center '>
          <div className = 'wrapper width-10 row justify-start align-center font-300 color-500 margin-right-2'>{ minValue }</div>
          <MultiRangeSlider className = 'wrapper width-75 row justify-start align-center ' min={1} max={600} step={1} minValue={minValue} maxValue={maxValue} ruler = 'false' onInput={(e) => { handleRange(e); }} />
          <div className = 'wrapper width-10 row justify-start align-center font-300 color-500 margin-left-2'>{ maxValue }</div>
        </div>
      </div>
      <div className = 'wrapper width-100 align-center column margin-bottom-2' id = 'input'>
        <div className = 'label font-300 color-500 weight-200'>Átmérő [mm]</div>
        <div className = 'wrapper width-100 height-30 row justify-start align-center ' >
          <input className = 'wrapper width-80 height-100 margin-right-2' id = 'range' type="range" min="1" max="500" value = {input.diameter} step="1" name = 'diameter'  onChange={handleChange} ></input>
          <div className = 'wrapper width-15 font-300 color-500'>{input.diameter}</div>
        </div>
      </div>
      <div className = 'wrapper width-100 align-center column margin-bottom-2' id = 'input'>
      <div className = 'label font-300 color-500 weight-200'>Közeg</div>
        <div className = 'wrapper width-100 align-center row' >
          <div className = 'wrapper width-50 height-30 row justify-center align-center padding-left-5'>
            <div className = 'wrapper width-90 row justify-right margin-right-1 font-200 color-500'>Hidraulika olaj</div>
            <input className = 'wrapper width-10 font-200 color-500' type="radio" name="medium" value="hidraulicoil" onChange={handleChange}></input>
          </div>
          <div className = 'wrapper width-30 height-30 row justify-center align-center padding-left-5'>
            <div className = 'wrapper width-80 row justify-right margin-right-1 font-200 color-500'>Gázolaj</div>
            <input className = 'wrapper width-10 font-200 color-500' type="radio" name="medium" value="oil" onChange={handleChange}></input>
          </div>
          <div className = 'wrapper width-30 height-30 row justify-center align-center padding-left-5'>
            <div className = 'wrapper width-80 row justify-right margin-right-1 font-200 color-500'>Víz</div>
            <input className = 'wrapper width-10 font-200 color-500' type="radio" name="medium" value="water" onChange={handleChange}></input>
          </div>
        </div>
      </div>
      <div className = 'wrapper width-100 align-center column' >
        <div className = 'button' onClick = {() => init()}>Szimuláció indítása</div>
      </div>
    </div>
    );

  }


export default InputController;

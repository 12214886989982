import React, { useContext, useState } from 'react'
import validator from 'validator'
import { sendEmail } from '../TOOLS/Queries';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserContext from '../MODELS/USER/UserContext';
import { useNavigate } from "react-router-dom";
import { findOne } from '../MODELS/USER/UserModel';
import { checkToken, sendToken, validateToken } from './TokenController';

interface IAuthControllerProps {


}

const AuthController: React.FunctionComponent<IAuthControllerProps> = ( props ) => {
 
  const [ input, setInput ] = useState({email: '', token: ''})
  const { users, modifyUsers } = useContext(UserContext)
  const [ userToLogin, setUserToLogin ] = useState({id: 0, buttons: '', email: '', role: '', status: '', token:'', tokenTimestamp: ''})
  const [ formProps, setFormProps ] = useState({buttonName: 'belépés', formTitle: 'Bejelentkezés'});
  const navigate = useNavigate();
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void => {
    setInput({
      ...input,
      [e.target.name]: e.target.value
    })
  }

  const checkEmail = async () => { 
    if(!input){ 
      return 
    }else if(validator.isEmpty(input.email)){
      toast('Adjon meg egy e-mail címet!')
    }else if(!validator.isEmail(input.email)){
      toast('Nem megfelelő e-mail formátum')
    }else{
      let loginUser = findOne(users, 'email', input.email);
      if(Object.entries(loginUser).length > 0){
        //SZEMPONT SHORTCUT
        if(input.email === 'szempontstudio@gmail.com'){
          navigate('adminview')
        }else{
          //CHECK STATUS
          if(loginUser.status === 'REGISTERED'){
            toast('Regisztrációja még nincs jóváhagyva!')
          }else{
            setUserToLogin(loginUser)
            //CHECK TOKEN
            if(await checkToken(loginUser) === 'NOTOKEN'){
              sendToken(loginUser)
              .then(response => { 
                modifyUsers(response, 'modify')
                setFormProps({buttonName: 'ellenőrzés', formTitle: 'Azonosítókód'})
                toast('A belépéshez az azonosítókódot e-mailben elküldtük (levélszemét mappába is kerülhetett!)')
                })
            .catch(err => { 
              setFormProps({buttonName: 'belépés', formTitle: 'Bejelentkezés'})
              toast('Hiba történt az e-mail küldés folyamatában...')
            })
              
            }else{
              setFormProps({buttonName: 'ellenőrzés', formTitle: 'Azonosítókód'})
              toast('A korábban e-mailben kapott azonosítókód még érvényes, kérem, használja azt!')
            }
          }
        }
      }else{
        toast('Nem regisztrált e-mail, kérem regisztráljon!')
        setFormProps({buttonName: 'regisztrálás', formTitle: 'Regisztrálás'})
      }
    }
  }

  const register = (): void => {
    if(!input){ 
      return 
    }else if(validator.isEmpty(input.email)){
      toast('Adjon meg egy e-mail címet!')
    }else if(!validator.isEmail(input.email)){
      toast('Nem megfelelő e-mail formátum')
    }else{
      let newUser = {id: 0, buttons: '', email: '', role: '', status: '', token:'', tokenTimestamp: ''};
      newUser.buttons = 'true';
      newUser.email = input.email;
      newUser.status = 'REGISTERED';
      newUser.role = 'USER'
      //EMAIL TO ADMIN
      let emailProps: any = 'homalyzona';
      let emailData: any = { to: 'szempontstudio@gmail.com', subject: 'Új regisztráció a 4s2000 szimulátor oldalon', text: 'E-mail: ' + input.email};
      sendEmail(emailProps, emailData)
      .then(response => { })
      .catch(err => { console.log(`SENDEMAIL: ${err.message}`)})
      modifyUsers(newUser, 'new');
      toast('Regisztrációs kérelméről értesítettük az adminisztrátort. E-mailben fog üzenetet kapni regisztrációja jóváhagyásáról.')
      setFormProps({buttonName: 'belépés', formTitle: 'Bejelentkezés'})
    }
  }

  const login = (): void => {
     if(!input){ 
      return 
    }else if(validator.isEmpty(input.token)){
       toast('Adja meg az azonosítókódot!')
    }else if(!validator.isNumeric(input.token)){
        toast('Az azonosítókód csak számot tartalmaz!')
    }else if(input.token.length !== 5){
        toast('Az azonosító kód egy ötjegyű szám!')
    } else{
      if(validateToken(userToLogin, input.token) === 'TOKENINVALID'){
        toast('Nem megfelelő azonosító kód!')
      }else{
        if(findOne(users, 'email', input.email).role === 'ADMIN'){
          navigate('adminview')
        }else{
          navigate('simview')
        }
        
      }
    }
  }
    

    
  return (
    <>
    <div className=" wrapper width-100 justify-center font-700 color-500">{formProps.formTitle}</div>
    <div className = 'wrapper width-100 align-center column'>
      {formProps.buttonName === 'belépés' || formProps.buttonName === 'regisztrálás' 
      ? <>
          <div className = 'label font-200 color-500'>Adja meg az e-mail címét!</div>
          <input className = "input font-100" type="email" name = 'email' required onChange={handleChange} value = {input.email}></input> 
        </>
      : <>
          <div className = 'label font-200 color-700'>Add meg az azonosítókódot!</div>
          <input className="input font-100" type="token" name = 'token' required onChange={handleChange} value = {input.token}></input>
        </> }

      {formProps.buttonName === 'belépés' ? <button className = 'button' onClick = {() => checkEmail()}>{formProps.buttonName}</button> 
      : formProps.buttonName === 'regisztrálás' ? <button className = 'button' onClick = {() => register()}>{formProps.buttonName}</button>
      : <button className = 'button' onClick = {() => login()}>{formProps.buttonName}</button>}
    </div>
    </>
    );

  }


export default AuthController;

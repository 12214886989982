import React, { useEffect } from 'react'
import AuthController from '../CONTROLLERS/AuthController';

const HomeView: React.FunctionComponent = () => {

    useEffect(() => { }, [])

    return(
        <div className = 'cont justify-center align-top' id = 'wall'>
            <div className="cont justify-center align-top column margin-top-3" id = {'login'}>
                <AuthController />
            </div>
        </div>
    )
}

export default HomeView
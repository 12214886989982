import React, { useEffect, useContext } from 'react'
import UserContext from '../MODELS/USER/UserContext';
import UserType from '../MODELS/USER/User';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { sendEmail } from '../TOOLS/Queries';

const AdminView: React.FunctionComponent = (props) => {

    const { users, modifyUsers } = useContext(UserContext)
    const navigate = useNavigate();

    useEffect(() => {

     }, [])

     const handleUser = (user: UserType, decision: string): void => {
        if(decision === 'APPROVED'){
            toast('A felhasználót e-mailben értesítjük regisztrációja jóváhagyásáról');
            user.status = 'APPROVED';
            user.buttons = 'false';
        }else{
            toast('A felhasználót e-mailben értesítjük regisztrációja elutasításáról');
            user.status = 'DENIED';
            user.buttons = 'false';
        }
 
         //EMAIL TO ADMIN
        let emailProps: any = 'homalyzona';
        let emailData: any = { to: user.email, subject: 'Az adminisztrátor jóváhagyta a regisztrációját.', text: 'Beléphet a 4S2000 szimulátor oldalára.'};
        sendEmail(emailProps, emailData)
        .then(response => {
            modifyUsers(user, 'modify')
         })
        .catch(err => { console.log(`SENDEMAIL: ${err.message}`)})
   
      }

    return(
        <div className = 'cont column justify-center align-center' id = 'adminwall'>
             <div className = 'wrapper width-50 height-60 column align-center' >
             <div className = 'wrapper width-100 align-center column margin-bottom-3' >
                <div className = 'button' onClick = {() => navigate('/simview')}>Szimuláció oldal</div>
            </div>
                {users.map((user, index) => 
                <div key = { index } className = 'row font-100 color-500' id = 'user'>
                    <div className = 'wrapper width-50 ' >{ user.email }</div>
                    <div className = 'wrapper width-15 ' >{ user.role }</div>
                    {user.buttons === 'true' ? 
                    <>
                    <div className = 'button width-15 height-100 justify-center align-center font-50 margin-right-3' onClick = {() => handleUser(user, 'APPROVED')}>jóváhagy</div>
                    <div className = 'button width-15 height-100 justify-center align-center font-50' onClick = {() => handleUser(user, 'DENIED')}>elutasít</div>
                    </>
                    : ''}
                
                </div>
                )}
             </div>
        </div>
    )
}

export default AdminView
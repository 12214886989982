import UserType from "../MODELS/USER/User";
import SimDataType from "../MODELS/SIMDATA/SimData";


export const modifier = (someObject: any, shallowArray: any[], caller: string) => {
  let filtered: any[] = [];
  filtered = shallowArray.filter((element) => element.id !== someObject.id);
  let filteredArray: any[] = Object.values(filtered)
  filteredArray.push(someObject)
  let header = setHeader(caller)
  filteredArray.unshift(header)
  return filteredArray
}

export const inserter = (someObject: any, shallowArray: any[], caller: string) => {
  someObject.id = getNextId(shallowArray)
  shallowArray.push(someObject)
  let header = setHeader(caller)
  shallowArray.unshift(header)
  return shallowArray
}

export const deleter = (someObject: any, shallowArray: any[], caller: string) => {
  var filtered = shallowArray.filter(function(element){ return element.id !== someObject.id; });
  let header = setHeader(caller)
  filtered.unshift(header)
  return filtered
}

export const loader = (ftpData: any, caller: string) => {
  let items: any[] = []
  ftpData.map((element: any) => ( items.push(loadModel(Object.values(element), caller)) ))
  return items
}

const loadModel = (values: any, caller: string) => {
  if(caller === 'user'){
    let user: UserType = {id: values[0], buttons: values[1], email: values[2], role: values[3], status: values[4], token: values[5], tokenTimestamp: values[6]};
    return user
  }else if (caller === 'simdata'){
    let simData: SimDataType = {diameter: values[0], ccmrpm: values[1], hidraulicoil: values[2], diesel: values[3], water: values[3]}
    return simData
  }
}


const setHeader = (caller: string) => {
  if(caller === 'user'){
     return {id: 'id', buttons: 'buttons', email: 'email', role: 'role', status: 'status', token: 'token', tokenTimestamp: 'tokentimestamp'}
  }else if(caller === 'simdata'){
    return {diameter: 'diameter', ccmrpm: 'ccmrpm', hidraulicoil: 'hidraulicoil', diesel: 'diesel', water: 'water'}
  }
  
}

const getNextId = (list: any[]) => {
  let listShallow: any[] = Object.values(list)
  let lastId: number = 0
      listShallow.sort((a, b) => a.id - b.id)
      lastId = listShallow[listShallow.length-1].id
  let plusOne = ++lastId
  return plusOne
}


import UserType from "./User";
import { filter, find } from "../Model";

export const findOne = (list: UserType[], parameter: string, searchParameter: string) => {
  return (find(list, parameter, searchParameter) as UserType);
}

export const filterByParameter = (list: UserType[], parameter: string, searchParameter: string) => {
  return (filter (list, parameter, searchParameter) as UserType[]) 
}






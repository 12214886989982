import UserType from '../MODELS/USER/User';
import 'react-toastify/dist/ReactToastify.css';
import { sendEmail } from '../TOOLS/Queries';




  export const checkToken = async (userToLogin: UserType) => { 
    return new Promise<any>(async (resolve) => {
        if(userToLogin.token === '' || ((new Date().getTime() - new Date(parseInt(userToLogin.tokenTimestamp)).getTime()) / 1000) > 18000){
          resolve('NOTOKEN')
        }else{
          resolve('TOKENEXIST') 
        }
      })
  }

  export const validateToken = (userToLogin: UserType, token: string) => { 
    if(userToLogin.token === token){
      return 'TOKENVALID'
    }else{
      return 'TOKENINVALID'
    }
  }

  export const sendToken = async (user: UserType) => {
    return new Promise<any>(async (resolve, reject) => {
      
      user.token = (Math.floor(Math.random() * 89999) + 10000).toString();

      user.tokenTimestamp = new Date().getTime().toString();
      let emailProps: any = 'homalyzona';
      let emailData: any = { to: user.email, subject: 'Azonosító kód a 4s2000 szimulátor.hu oldalra való belépéshez', text: 'Azonosító kód: ' + user.token};
      
      //EMAIL TO USER
      sendEmail(emailProps, emailData)
      .then(response => { 
        resolve(user)})
      .catch(err => { 
        reject(`SENDEMAIL: ${err.message}`)})
        
    })
  }

 





import { getData } from '../TOOLS/Queries'
import { loader } from '../TOOLS/CSVHelper'


export const loadSimData = async () => {
    return new Promise<any>(async (resolve, reject) => {
        getData('hegyibarangolas', 'simdata', '', 0)
        .then((response: any) => { 
            let answer: any
            answer = (loader(response.data, 'simdata'))
            resolve(answer)
        })
        .catch(err => { reject(err)})
    })
}






import React from "react";
import Plot from "react-plotly.js";


class Graphs extends React.Component {

  render() {
    return (
      <Plot
        data={[
          {
            type: "surface",
            z: this.props.dataObject.zAxis,
            x: this.props.dataObject.yAxis,
            y: this.props.dataObject.xAxis,
          }
        ]}
        layout={{
          width: 800,
          height: 440,
          paper_bgcolor:'rgba(255, 255, 255, 0.7)',
          margin: {
            l: 20,
            r: 20,
            b: 20,
            t: 80,
            pad: 2
          },
          title: 'Gömbszivattyú',
          annotations: [
            {
              text: 'szimulált térfogatáram [ccm/min]',
              font: {
                size: 14,
                color: "#444444"
              },
              showarrow: false,
              align: "center",
              x: 0.5,
              y: 1.1,
              xref: "paper",
              yref: "paper"
            }
          ],
          scene: {
            xaxis: {
              title: 'szivattyú átmérő [mm]',
              titlefont: {
                family: "Courier New, monospace",
                size: 12,
                color: "#444444"
              }
            },
            yaxis: {
              title: 'fordulatszám [1/min]',
              titlefont: {
                family: "Courier New, monospace",
                size: 12,
                color: "#444444"
              }
            },
            zaxis: {
              title: 'szimulált térfogatáram [ccm/min]',
              titlefont: {
                family: "Courier New, monospace",
                size: 12,
                color: "#444444"
              }
            }
          }
        }}
      />
    );
  }
}

export default Graphs;

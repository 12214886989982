import SimDataType from "./SimData";
import { filter, find } from "../Model";


export const findOne = (list: SimDataType[], parameter: string, searchParameter: string) => {
  return (find(list, parameter, searchParameter) as SimDataType);
   
}

export const filterByParameter = (list: SimDataType[], parameter: string, searchParameter: string) => {
  return (filter (list, parameter, searchParameter) as SimDataType[]) 
}






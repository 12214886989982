import axios, { AxiosResponse } from 'axios'


export const getFtpList = (ftp: string, queryId: number, user: string) => {
let ftpArray : any[] = []
let responseObject: any = {data: [], queryId: ''}
  return new Promise((resolve, reject) => {
    axios.post('https://homalyzona.herokuapp.com/getftp/getftp', { ftp: ftp, user: user })
    //axios.post('http://localhost:3000/getftp/getftp', { ftp: ftp, user: user })
    .then((response: AxiosResponse) => {
      response.data.map((element: any) => ( 
        ftpArray.push(element.name) ))
        responseObject.data = ftpArray;
        responseObject.queryId = queryId
        resolve(responseObject)
    })
    .catch(error => {
      reject(error);
    })
  })
};

export const getData = (ftp: string, fileName: string, user: string, queryId: number) => {
  let responseObject: any = {data: [], queryId: ''}
  return new Promise<any>((resolve, reject) => {
    axios.post<any>('https://homalyzona.herokuapp.com/getdata/getdata', { ftp: ftp, fileName : fileName, user: user })
    //axios.post<any>('http://localhost:3000/getdata/getdata', { ftp: ftp, fileName : fileName, user: user })
      .then((response: AxiosResponse) => { 
        responseObject.data = response.data;
        responseObject.queryId = queryId
        resolve(responseObject);
      }).catch(error => { reject(error.response.data); })
  })
};

export const saveData = async (data : any, ftp: string, fileName: string, queryId: number) => {
  let responseObject: any = {data: [], queryId: ''}
  return new Promise<any>((resolve, reject) => {
    axios.post('https://homalyzona.herokuapp.com/savedata/savedata', { data: data, ftp: ftp, fileName: fileName })
    //axios.post('http://localhost:3000/savedata/savedata', { data: data, ftp: ftp, fileName: fileName })
    .then((response: AxiosResponse) => { 
      responseObject.data = response.data;
      responseObject.queryId = queryId
      resolve(responseObject);
    })
    .catch( error => { reject(error); });
  })
};

export const sendEmail = async (emailProps : any, emailData : any) => {
  return new Promise((resolve, reject) => {
    axios.post('https://homalyzona.herokuapp.com/homalymail/homalymail', { emailProps : emailProps, emailData : emailData })
    .then((response: AxiosResponse) => { resolve(response.status) })
    .catch( error => { reject(error) });
  })
  
};
import React, { useEffect, useState } from 'react'
import InputController from '../CONTROLLERS/InputController';
import PlotlyGraph from '../TOOLS/PlotlyGraph';
import Video from '../TOOLS/Video';
import VideoOpen from '../TOOLS/VideoOpen';
import ReactLoading from 'react-loading';
import { CSVLink } from "react-csv";

const SimView: React.FunctionComponent = () => {


    interface axis {xAxis: number[], yAxis: number[], zAxis: number[], diameterVolumes: any[], a: string, b: string, c: string, d: string, filename: string};
      
    const [datas, setDatas] = useState<axis>({xAxis: [], yAxis: [], zAxis: [], diameterVolumes: [], a: '', b: '', c: '', d: '', filename: ''});
    const [background, setBackground] = useState('openwall');
    const [on, setOn] = useState('on');
    const [videoOpenonon, setVideoOpenon] = useState('videoon');
    const [inputwrapper, setInputwrapper] = useState('inputwrapperout');
    const [plotwrapper, setPlotwrapper] = useState('plotwrapperout');
    const [videoplot, setVideoplot] = useState('video');
    const [loading, setLoading] = useState(false);
    const [ width, setWidth ] = React.useState(window.innerWidth);

    const updateDatas = (dataObject: axis): void => { setDatas(dataObject) };

    const updateVideoplot = (): void => { 
        if(videoplot === 'video'){ setVideoplot('plot');
        }else{ setVideoplot('video'); }
    };
    const updateLoading = (setter: boolean): void => { setLoading(setter) };

    const startSimulator = (): void => { 
        setOn('onout');
        setVideoOpenon('videoout')
        setTimeout(() => { 
            setBackground('openwallout')
            setBackground('simwall')
            if(width > 1700){
                setInputwrapper('inputwrapper')
            }else{
                setInputwrapper('inputwrapperLowres')
            }
            
            setPlotwrapper('plotwrapper')
            
        }, 500)
    }

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, [loading])

    return(
            <>
            <div className = 'cont row justify-left align-top padding-top-5 overflow-hidden' id = { background }>
                <div className = 'wrapper width-10 height-15 ' id = { on } onClick = {() => startSimulator()}></div>
                <div className = 'wrapper width-80 height-90 'id = { videoOpenonon } ><VideoOpen /></div>
                <div className = 'wrapper row width-40 height-90 justify-right align-top margin-top-2 maring-right-1' id = { inputwrapper } >
                    <InputController datas = { datas } updateDatas = { updateDatas } videoplot = { videoplot } updateVideoplot = { updateVideoplot } loading = { loading } updateLoading = { updateLoading }/>
                </div>
                {loading ? 
                    <div className = 'wrapper column width-80 height-80 justify-center align-center font-500 z-index-100 ' id = 'login'>
                        Modell betöltése
                    <ReactLoading type='cylon' color='blue' height={'20%'} width={'20%'} />
                    </div>
                : <div className = 'wrapper width-50 height-90 justify-center align-center margin-bottom-2 margin-left-1 ' id = { plotwrapper }>
                    {videoplot === 'video' ? 
                    <Video /> 
                    : <div className = 'wrapper column width-100 height-95 justify-top align-center' >
                        <div className = 'wrapper row justify-left align-top font-200 margin-bottom-2' id = 'details'>
                            <div className = 'wrapper column width-40 height-90 justify-top align-center font-200 ' >
                                <div className = 'wrapper font-100 margin-bottom-3' >Szimulált működési függvény</div>
                                <div className = 'wrapper margin-bottom-2' id = 'fg' ></div>
                                <div className = 'wrapper row width-90 height-15 font-50 padding-left-10'  >
                                    <div className = 'wrapper row width-20 height-100 '  >a:</div>
                                    <div className = 'wrapper row width-70 height-100 '  >{datas.a}</div>
                                </div>
                                <div className = 'wrapper row width-90 height-15 font-50 padding-left-10'  >
                                    <div className = 'wrapper row width-20 height-100 '  >b:</div>
                                    <div className = 'wrapper row width-70 height-100 '  >{datas.b}</div>
                                </div>
                                <div className = 'wrapper row width-90 height-15 font-50 padding-left-10'  >
                                    <div className = 'wrapper row width-20 height-100 '  >c:</div>
                                    <div className = 'wrapper row width-70 height-100 '  >{datas.c}</div>
                                </div>
                                <div className = 'wrapper row width-90 height-15 font-50 padding-left-10'  >
                                    <div className = 'wrapper row width-20 height-100 '  >d:</div>
                                    <div className = 'wrapper row width-70 height-100 '  >{datas.d}</div>
                                </div>
                                <div className = 'wrapper width-70 height-20 font-50 margin-top-5' >
                                    <CSVLink className = 'button' filename = {datas.filename} data={datas.diameterVolumes} >Adatok exportálása</CSVLink>
                                    
                                </div>
                            </div>
                            <div className = 'wrapper column width-60 height-90 justify-left align-center font-200 ' >
                                <div className = 'wrapper font-100 weigth-400 margin-top-2' >Az átmérőhöz tartozó térfogatáramok </div>
                                <div className = 'wrapper column width-80 height-80 white padding-bottom-1' >
                                    <div className = 'wrapper row width-80 height-15 margin-bottom-4' >
                                        <div className = 'wrapper width-40 height-10 font-50' >rpm </div>
                                        <div  className = 'wrapper width-30 height-10 font-50' >térfogatáram </div>
                                    </div>
                                    <div className = 'wrapper column width-100 height-90 justify-left align-center font-50 overflow-y  ' >
                                    {datas.diameterVolumes.map((data, index) => 
                                        <div key = { index } className = 'wrapper row width-90 height-15 margin-bottom-2 ' >
                                            <div className = 'wrapper width-30 height-10 ' >{data.rpm} </div>
                                            <div className = 'wrapper width-30 height-10 ' >{data.volume} </div>
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PlotlyGraph dataObject = { datas }/>
                      </div>
                    }
                  </div>
                }

            </div>
        </>
    )

}

export default SimView
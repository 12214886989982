import UserType from "../MODELS/USER/User";
import { getData, saveData } from '../TOOLS/Queries'
import { loader, modifier, inserter } from '../TOOLS/CSVHelper'


export const loadUsers = async () => {
    return new Promise<any>(async (resolve, reject) => {
        getData('hegyibarangolas', 'user4s2000', '', 0)
        .then((response: any) => { 
            let answer: any
            answer = (loader(response.data, 'user'))
            resolve(answer)
        })
        .catch(err => { reject(err)})
    })
}


export const usersModifier = async (user: UserType, usersShallow: UserType[], action: string) => {
    if(action === 'modify'){
        return new Promise<any>(async (resolve, reject) => {
            saveData(modifier(user, usersShallow, 'user'), 'hegyibarangolas', 'user4s2000', 0)
            .then((response: any) => { resolve(response) })
            .catch(err => { reject(err)})
        })
    }else if(action === 'new'){
        return new Promise<any>(async (resolve, reject) => {
            saveData(inserter(user, usersShallow, 'user'), 'hegyibarangolas', 'user4s2000', 0)
            .then((response: any) => { resolve(response) })
            .catch(err => { reject(err)})
        })
    }else if(action === 'delete'){
        /*
        return new Promise<any>(async (resolve, reject) => {
            saveData(deleter(stuff, stuffsShallow, 'stuff'), 'leltar', 'stuffs', 0)
            .then((response: any) => { resolve(response) })
            .catch(err => { reject(err)})
        })
        */
    }
}




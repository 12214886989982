import { createContext } from 'react';
import SimDataInterface from './SimDataInterface';


const defaultState = {
   simData: [{diameter: 0, ccmrpm: 0, hidraulicoil: 0, diesel: 0, water: 0}],
   updateSimData: (diameter: number, rangeMin: number, rangeMax: number, medium: string) => {},

};

const SimDataContext = createContext<SimDataInterface>(defaultState);

export default SimDataContext

